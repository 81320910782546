<template>
  <div>
    <slot name="filters-top"></slot>
    <v-card :flat="flat" :tile="tile">
      <v-card-title>
        {{ title ? title : 'Listado' }}
        <slot name="title"></slot>
        <!-- <span class="title-vuely" v-else>{{`Listado ${$nuxt.$route.name}`}}</span> -->
        <v-spacer></v-spacer>
        <slot name="filters"></slot>
        <v-text-field
          v-if="!hideSearch"
          label="Búsqueda rápida"
          v-model="search"
          append-icon="search"
          single-line
          hide-details
          clearable
          @keyup="updateFilter"
          @input="updateFilter"></v-text-field>
        <v-btn depressed small dark color="info" @click="excelExport" class="mt-5 ml-3">
          <v-icon class="mr-1" size="20">mdi-file-excel</v-icon>
          exportar
        </v-btn>
      </v-card-title>

      <slot></slot>

      <div style="height: 100%">
        <div class="ag-container">
          <ag-grid-vue
            style="width: 100%"
            :style="{ height: height + 'vh' }"
            :suppressCellSelection="true"
            class="ag-theme-material ag-grid"
            id="ag-grid"
            :columnDefs="headers"
            @grid-ready="onGridReady"
            :rowModelType="rowModelType"
            :pagination="true"
            :sideBar="sideBar"
            :autoGroupColumnDef="autoGroupColumnDef"
            :groupSelectsChildren="groupSelectsChildren"
            :animateRows="true"
            :localeText="localeText"
            :paginationAutoPageSize="false"
            :quickFilter="search"
            @rowClicked="clickRow"
            :rowSelection="rowSelection"
            :suppressRowClickSelection="suppressRowClickSelection"
            :masterDetail="!!detailCellRenderer"
            :detailRowHeight="detailRowHeight"
            :detailCellRenderer="detailCellRenderer"
            :detailCellRendererParams="detailCellRendererParams"
            rowGroupPanelShow="never"
            :overlayLoadingTemplate="overlayLoadingTemplate"
            :gridOptions="options"
            :frameworkComponents="frameworkComponents"
            :excelStyles="excelStyles"
            @selectionChanged="onSelectionChange()"
            @firstDataRendered="autoSize"
            :paginationPageSize="paginationPageSize"
            :cacheBlockSize="cacheBlockSize"></ag-grid-vue>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'AgDataTableNew',
  props: {
    options: null,
    detailRowHeight: { type: Number, default: null },
    detailCellRenderer: { type: String, default: null },
    detailCellRendererParams: null,
    title: { type: String, default: null },
    noRowsToShow: { type: String, default: 'No existen datos para mostrar' },
    height: { type: String, default: '72.5' },
    tile: { type: Boolean, default: true },
    flat: { type: Boolean, default: true },
    groupSelectsChildren: { type: Boolean, default: true },
    showHover: { type: Boolean, default: true },
    hideOverlay: { type: Boolean, default: false },
    hideSearch: { type: Boolean, default: false },
    showSelect: { type: Boolean, default: false },
    rowSelection: { type: String, default: null },
    sizeColumnsToFit: { type: Boolean, default: false },
    disableClickRow: { type: Boolean, default: false },
    emitClickRow: { type: Boolean, default: false },
    suppressRowClickSelection: { type: Boolean, default: false },
    parameterFilterName: { type: String, default: null },
    parameterFilterValue: { type: String, default: null },
    autoGroupColumnDef: {
      type: Object,
      default: () => {
        resizable: true
      },
    },
    urlData: { type: String, default: null },
    headers: { type: Array, default: () => [] },
    version: { type: Number, default: 1 },
    //   items: {type: Array, required: true, default: () => []},
  },
  mounted() {
    setTimeout(() => (this.loading = false), 1500)
    if (!this.showHover) this.num_hover = 3
  },
  beforeMount() {
    this.localeText = {
      equals: 'es igual',
      notEqual: 'no es igual',
      lessThan: 'es menor',
      greaterThan: 'es mayor',
      inRange: 'esta en el rango',
      contains: 'contiene',
      noRowsToShow: this.noRowsToShow,
      filters: 'Filtros',
      columns: 'Columnas',
      loadingOoo: 'Por favor, espere mientras se cargan los datos...',
    }
    this.frameworkComponents = {
      SwitchRenderer: 'SwitchRenderer',
      DeleteRenderer: 'DeleteRenderer',
      BooleanCellEditor: 'BooleanCellEditor',
      AutocompleteCellEditor: 'AutocompleteCellEditor',
      SelectCellEditor: 'SelectCellEditor',
      TextCellEditor: 'TextCellEditor',
      DateCellEditor: 'DateCellEditor',
      AccionCorrectivaRenderer: 'AccionCorrectivaRenderer',
    }
    this.sideBar = {
      toolPanels: ['filters', 'columns'],
    }
    this.excelStyles = [
      {
        id: 'default',
        dataType: 'string',
      },
      {
        id: 'stringType',
        dataType: 'string',
      },
      {
        id: 'string',
        dataType: 'string',
      },
    ]
  },
  data() {
    return {
      file: undefined,
      singleSelect: false,
      loading: false,
      search: '',
      page: 1,
      pageCount: 0,
      localeText: {},
      sideBar: {},
      gridApi: null,
      columnApi: null,
      frameworkComponents: null,
      overlayLoadingTemplate: null,
      num_hover: 8,
      excelStyles: null,

      rowModelType: 'serverSide',
      paginationPageSize: 15,
      cacheBlockSize: 15,

      // defaultColDef: {
      //   flex: 1,
      //   minWidth: 90,
      //   resizable: true,
      // },
    }
  },
  computed: {
    gridOptions: () => this.options,
    IS_LIST() {
      return !this.$route.params.uuid
    },
    CAN_EDIT() {
      // return true
      return this.IS_LIST ? this.$store.state.permisos.can_edit : true
    },
  },
  watch: {
    //   items: function (value) {
    //     // NOTA: cuando se cargan items desde cache,
    //     // no se esconde el overlay porque no hay gridapi, por algun motivo
    //     // solucionado quitando que se muestre el overlay en primer lugar linea 230
    //     if (value && value.length > 0 && this.gridApi) {
    //       this.gridApi.hideOverlay()
    //     }
    //     if (value && value.length === 0 && this.gridApi) {
    //       this.gridApi.hideOverlay()
    //     }
    //   },
    search() {
      this.updateDataSource()
    },
  },
  methods: {
    async autoSize() {
      // if (this.columnApi) {
      //   const rowGroupColumnsIds = this.columnApi
      //     .getRowGroupColumns()
      //     .map((column) => column.getColId())
      //   await this.columnApi.removeRowGroupColumns(rowGroupColumnsIds)
      //   setTimeout(() => {
      //     this.columnApi.autoSizeAllColumns()
      //     this.columnApi.addRowGroupColumns(rowGroupColumnsIds)
      //     setTimeout(() => {
      //       this.columnApi.autoSizeColumn('ag-Grid-AutoColumn')
      //       this.gridApi.expandAll()
      //     }, 100)
      //   }, 100)
      // }
    },
    checkFile(e) {
      console.info(e)
      console.info(this.file)
    },
    excelImport() {},
    excelExport() {
      // Get Headers keys
      const keys = []
      const headers = this.headers
        .filter((e) => e.field || e.children)
        .map((e) => e.field || e.children)
      for (let i = 0; i < headers.length; i++) {
        const element = headers[i]
        Array.isArray(element)
          ? (keys = keys.concat(element.map((e) => e.field)))
          : keys.push(element)
      }
      const params = {
        allColumns: true,
        columnKeys: keys,
        processCellCallback: (params) => {
          const cellClass = params.column.colDef.cellClass
          const value = params.value
          return cellClass === 'date'
            ? this.$moment(params.value).format('DD-MM-YYYY')
            : params.value
        },
      }
      this.gridApi.exportDataAsExcel(params)
    },

    onGridReady(params) {
      this.gridApi = params.api
      this.columnApi = params.columnApi
      // if (this.sizeColumnsToFit) this.gridApi.sizeColumnsToFit();
      this.gridApi.expandAll()
      this.$emit('gridApi', this.gridApi)
      this.$emit('columnApi', this.columnApi)

      // params.api.sizeColumnsToFit();
      this.gridColumnApi = params.columnApi

      this.updateDataSource()
    },
    updateDataSource() {
      const datasource = {
        getRows: (params) => {
          const page = Math.floor(params.request.startRow / this.paginationPageSize) + 1

          let body = {
            page: page,
            itemsPerPage: this.paginationPageSize,
            // filter: this.search || '',
          }
          if (this.parameterFilterName && this.parameterFilterValue) {
            body[this.parameterFilterName] = this.parameterFilterValue
          }
          if (this.version) {
            body['version'] = this.version
          }

          // console.log('body', body);
          this.$axios
            .get(this.urlData, { params: body })
            .then((response) => {
              console.log(response)
              if (response.data['hydra:member'] && response.data['hydra:totalItems']) {
                params.successCallback(
                  response.data['hydra:member'],
                  response.data['hydra:totalItems']
                )
              } else {
                params.successCallback([], 0)
                //set no items in response data
                this.gridApi.showNoRowsOverlay()
                console.error('No items in response data')
              }
            })
            .catch((error) => {
              console.error('Error fetching data from API: ', error)
            })
        },
      }

      this.gridApi.setServerSideDatasource(datasource)
    },
    updateFilter() {
      this.gridApi.setQuickFilter(this.search)
    },
    clickRow(row) {
      //or OpcionCategoria
      if (
        row.data['@type'] == 'OpcionRespuesta' ||
        row.data['@type'] == 'OpcionCategoria' ||
        row.data['@type'] == 'PlantillaPreguntaChecklist'
        && !this.disableClickRow
      ) {
        // console.log('es opcion respuesta')
        //emitir evento para que se abra el dialogo de edicion y escucharlo en _uuid.vue
        this.$emit('edit-dialog-opened', row.data)
        return
      }

      if (!this.disableClickRow && !this.emitClickRow) {
        if (row.data) {
          if (this.CAN_EDIT) {
            let route = this.$nuxt.$route.path
            route = `/${route}/${row.data.uuid}`
            this.$router.push(route)
          } else {
            this.$store.commit('notification/show', {
              text: 'No tiene permisos suficientes.',
              color: 'error',
              timeout: 5000,
            })
          }
        }
      } else if (!this.disableClickRow && this.emitClickRow) {
        this.$emit('click-row', row.data)
      }
    },
    onSelectionChange() {
      // console.log('selectionChanged')
      const selection = this.gridApi.getSelectedRows()
      this.$emit('selectionChanged', selection)
    },
  },
}
</script>

<style>
.ag-header-cell,
.ag-header-group-cell {
  border-right: 0px solid #e2e2e2 !important;
  border-bottom: 4px solid grey !important;
}

.theme--light.v-data-table tbody tr:hover:not(.v-data-table__expand-row) {
  background: #eeeeee;
  cursor: pointer !important;
}

.ag-container {
  height: 100% !important;
  display: flex;
  flex-direction: column;
}

.ag-grid {
  height: 1px;
  min-height: 1px;
  flex-grow: 1;
  --ag-odd-row-background-color: #ececec;
  --ag-even-row-background-color: #d8d8d8;
}

.ag-grid .ag-row-odd {
  background-color: var(--ag-odd-row-background-color);
}

.ag-grid .ag-row-even {
  background-color: var(--ag-even-row-background-color);
}

.ag-cell {
  font-size: 1.1em !important;
}

.ag-header-cell-text {
  font-size: 1.3em !important;
  font-weight: bold;
}

.ag-theme-material .ag-side-bar .ag-side-buttons {
  width: 100% !important;
  padding: 0 !important;
}

.ag-theme-material .ag-side-bar .ag-side-buttons .ag-side-button button {
  min-height: 0 !important;
  padding: 0 0 16px 0 !important;
}
</style>
