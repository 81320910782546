<template>
  <div v-if="isNotGroup">
    <span v-if="!hide">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon
            style="font-size: 20px"
            color="success"
            :disabled="!CAN_CLONE"
            v-on="on"
            @click.stop="controlClickClone()"
            >mdi-content-copy</v-icon
          >
        </template>
        <span>Clonar</span>
      </v-tooltip>
    </span>

    <span v-if="!hide">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon
            style="font-size: 20px"
            color="red"
            :disabled="!CAN_DELETE"
            v-on="on"
            @click.stop="controlClick()"
            >mdi-delete-outline</v-icon
          >
        </template>
        <span>Eliminar</span>
      </v-tooltip>
    </span>

    <confirmation
      v-model="confirmDelete"
      :text="params.text || '¿Eliminar el objeto seleccionado?'"
      colorButton="error"
      @confirm="deleteItem()" />

    <confirmation
      v-model="confirmClone"
      :text="params.text2 || '¿Clonar el objeto seleccionado?'"
      colorButton="primary"
      textButton="Clonar"
      @confirm="cloneItem()" />
  </div>
</template>

<script>
import Confirmation from '~/components/Confirmation'

export default {
  components: {
    Confirmation,
  },
  data: function () {
    return {
      confirmDelete: false,
      confirmClone: false,
    }
  },
  computed: {
    IS_LIST() {
      // console.log('hide', this.params)
      return !this.$route.params.uuid
    },
    CAN_DELETE() {
      // console.log('permisos', this.$store.state.permisos)
      // return true
      // return this.IS_LIST ? this.$store.state.permisos.can_delete || this.isKaam : false
      return this.IS_LIST ? this.isKaam : false
    },
    CAN_CLONE() {
      // console.log('permisos', this.$store.state.permisos)
      // return true
      return this.IS_LIST ? this.$store.state.permisos.can_clone || this.isAdminGroup : false
    },
    isNotGroup() {
      // console.log('hide', this.params)
      return !!this.params.data
    },
    isAdminGroup() {
      return this.$store.getters.isAdminGroup
      // de momento only for kaam
      // return this.$store.getters.isKaam
    },
    isKaam() {
      return this.$store.getters.isKaam
    },
    hide() {
      // console.log('hide', this.params)
      return this.params.value === true
    },
  },
  methods: {
    init() {
      if (this.params.init instanceof Function) this.params.init()
    },
    controlClick() {
      if (this.params.dispatch || this.params.confirm) this.confirmDelete = true
      else this.deleteItem()
    },
    async deleteItem() {
      this.confirmDelete = false
      let gridOptions = this.$parent.gridOptions
      let gridApi = this.$parent.gridOptions.api
      let response = { status: 200 }
      if (this.params.dispatch && this.params.node.data.uuid) {
        response = await this.$store.dispatch(`${this.params.dispatch}`, this.params.node.data.uuid)
      }
      if (response && response.status !== 403) {
        // Eliminamos la row del ag-grid y refrescamos
        this.$store.commit('notification/show', {
          text: this.params.onSuccess || 'Elemento eliminado correctamente',
          color: 'success',
          timeout: 3000,
        })
        const groupedItems = this.params.node.allLeafChildren
        if (groupedItems && groupedItems.length) {
          for (let i = 0; i < groupedItems.length; i++) {
            const row = groupedItems[i]
            let indexRow = gridOptions.rowData.indexOf(row.data)
            gridOptions.rowData.splice(indexRow, 1)
          }
        } else {
          let indexRow = gridOptions.rowData.indexOf(this.params.node.data)
          gridOptions.rowData.splice(indexRow, 1)
        }
        gridApi.refreshCells()
        this.init()
      } else {
        this.$store.commit('notification/show', {
          text: this.params.onError || 'No se puede eliminar el objeto seleccionado',
          color: 'error',
          timeout: 3000,
        })
      }
    },
    controlClickClone() {
      if (this.params.dispatch2 || this.params.confirm) this.confirmClone = true
      else this.cloneItem()
    },
    async cloneItem() {
      this.confirmClone = false
      let gridOptions = this.$parent.gridOptions
      let gridApi = this.$parent.gridOptions.api
      let response = { status: 200 }
      if (this.params.dispatch2 && this.params.node.data.uuid) {
        response = await this.$store.dispatch(
          `${this.params.dispatch2}`,
          this.params.node.data.uuid
        )
      }
      //añadimos la row al ag-grid
      let newRow = Object.assign({}, this.params.node.data)
      newRow.nombre = newRow.nombre + ' (Copia)'
      newRow.uuid = response.uuid
      gridOptions.rowData.push(newRow)
      // refrescamos
      gridApi.refreshCells()
      this.init()
    },
  },
}
</script>
